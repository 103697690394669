import React, { useRef, useEffect } from 'react';
import { IS_SERVER } from 'src/constants';

const ids = [];

const serverCalculations = () => `{exec}
  if (!('components' in static_data)) static_data.components = {};
  if (!('IconPlus' in static_data.components)) static_data.components.IconPlus = 0;
  else static_data.components.IconPlus++;
{/exec}`;

export default ({ fill = '#fff', ...props }) => {
  const id = useRef(null);

  if (!IS_SERVER && id.current === null) {
    id.current = 0;
    while (ids.includes(id.current)) id.current++;
    ids.push(id.current);
  }

  useEffect(() => {
    return () => {
      const index = ids.indexOf(id.current);
      if (index >= 0) ids.splice(index, 1);
    };
  }, []);

  return (
    <>
      {IS_SERVER && serverCalculations()}
      <svg {...props} width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter={`url(#IconPlus0_${IS_SERVER ? '{print static_data.components.IconPlus}' : id.current})`}>
          <rect x="4" y="12" width="14" height="2" rx="1" fill={fill}/>
        </g>
        <g filter={`url(#IconPlus1_${IS_SERVER ? '{print static_data.components.IconPlus}' : id.current})`}>
          <rect x="12" y="6" width="14" height="2" rx="1" transform="rotate(90 12 6)" fill={fill}/>
        </g>
        <defs>
          <filter id={`IconPlus0_${IS_SERVER ? '{print static_data.components.IconPlus}' : id.current})`} x="0" y="6" width="22" height="14" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="2"/>
            <feGaussianBlur stdDeviation="2"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3027:6093"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="-2"/>
            <feGaussianBlur stdDeviation="2"/>
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"/>
            <feBlend mode="normal" in2="effect1_dropShadow_3027:6093" result="effect2_dropShadow_3027:6093"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3027:6093" result="shape"/>
          </filter>
          <filter id={`IconPlus1_${IS_SERVER ? '{print static_data.components.IconPlus}' : id.current})`} x="6" y="0" width="10" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="2"/>
            <feGaussianBlur stdDeviation="2"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3027:6093"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="-2"/>
            <feGaussianBlur stdDeviation="2"/>
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"/>
            <feBlend mode="normal" in2="effect1_dropShadow_3027:6093" result="effect2_dropShadow_3027:6093"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3027:6093" result="shape"/>
          </filter>
        </defs>
      </svg>
    </>
  );
};
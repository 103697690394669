import React, { useEffect, useRef } from 'react';
import { IS_SERVER } from 'src/constants';

const ids = [];

const serverCalculations = () => `{exec}
	if (!('components' in static_data)) static_data.components = {};
	if (!('IconBellCrossed' in static_data.components)) static_data.components.IconBellCrossed = 0;
	else static_data.components.IconBellCrossed++;
{/exec}`;

function BellCrossed({ color = '#BFD0E0' }) {
	const id = useRef(null);

	if (!IS_SERVER && id.current === null) {
		id.current = 0;
		while (ids.includes(id.current)) id.current++;
		ids.push(id.current);
	}

	useEffect(() => {
		return () => {
			const index = ids.indexOf(id.current);
			if (index >= 0) ids.splice(index, 1);
		};
	}, []);

	return (
		<>
			{IS_SERVER && serverCalculations()}
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clip-path={`url(#IconBellCrossed0_${IS_SERVER ? '{print static_data.components.IconBellCrossed}' : id.current})`}>
					<mask id={`IconBellCrossed1_${IS_SERVER ? '{print static_data.components.IconBellCrossed}' : id.current}`} style={{ 'mask-type': 'alpha' }} maskUnits="userSpaceOnUse" x="-1" y="-2" width="18" height="21">
						<path d="M2 18.5L15.5 5L16 2L3 15L-0.5 18.5V-1.5H17V18.5H2Z" fill="#D9D9D9"/>
					</mask>
				<g mask={`url(#IconBellCrossed1_${IS_SERVER ? '{print static_data.components.IconBellCrossed}' : id.current})`}>
					<path d="M14 11H13V7C13 4.6 11.3 2.6 9 2.1V2V1C9 0.4 8.6 0 8 0C7.4 0 7 0.4 7 1V2V2.1C4.7 2.6 3 4.6 3 7V11H2C1.4 11 1 11.4 1 12C1 12.6 1.4 13 2 13H3H5H11H14C14.6 13 15 12.6 15 12C15 11.4 14.6 11 14 11ZM11 11H5V7C5 5.3 6.3 4 8 4C9.7 4 11 5.3 11 7V11ZM6.1 14C6 14.2 6 14.3 6 14.5C6 15.3 6.7 16 7.5 16H8.5C9.3 16 10 15.3 10 14.5C10 14.3 10 14.2 9.9 14H6.1Z" fill={color}/>
				</g>
					<rect x="1" y="14.2632" width="17.3428" height="2" rx="1" transform="rotate(-45 1 14.2632)" fill={color}/>
				</g>
				<defs>
					<clipPath id={`IconBellCrossed0_${IS_SERVER ? '{print static_data.components.IconBellCrossed}' : id.current}`}>
						<rect width="16" height="16" fill="white"/>
					</clipPath>
				</defs>
			</svg>
		</>
	);
}

export default BellCrossed;

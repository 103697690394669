import React from 'react';


export default ({ color, ...res }) => {
  return (
    <svg {...res} width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 9L8 2L15 9" stroke={color} strokeWidth="2" strokeLinecap="round"/>
    </svg>
  );
}

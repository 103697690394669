import React from 'react';
import { IS_SERVER } from 'src/constants';
import { useIconId } from 'src/front/hooks';

const ids = [];

function Sunset() {
	const id = useIconId(IS_SERVER ? 'Sunset' : ids);

	return (
		<>
			{IS_SERVER && id}
			<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
				<mask id={`IconSunset_${IS_SERVER ? '{print static_data.components.IconSunset}' : id.current}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
					<circle cx="16" cy="16" r="16" fill="#D9D9D9"/>
				</mask>
				<g mask={`url(#IconSunset_${IS_SERVER ? '{print static_data.components.IconSunset}' : id.current})`}>
					<circle cx="16" cy="16" r="16" fill="#BFD0E0"/>
					<path opacity="0.2" d="M31 10C31 11.9698 30.612 13.9204 29.8582 15.7403C29.1044 17.5601 27.9995 19.2137 26.6066 20.6066C25.2137 21.9995 23.5601 23.1044 21.7403 23.8582C19.9204 24.612 17.9698 25 16 25C14.0302 25 12.0796 24.612 10.2597 23.8582C8.43986 23.1044 6.78628 21.9995 5.3934 20.6066C4.00052 19.2137 2.89563 17.5601 2.14181 15.7403C1.38799 13.9204 1 11.9698 1 10L6.5 10L10.2597 18H19L24 13L25 10H31Z" fill="white"/>
					<path opacity="0.2" d="M33 10C33 12.2325 32.5603 14.4431 31.706 16.5056C30.8516 18.5682 29.5994 20.4422 28.0208 22.0208C26.4422 23.5994 24.5682 24.8516 22.5056 25.706C20.4431 26.5603 18.2325 27 16 27C13.7675 27 11.5569 26.5603 9.49438 25.706C7.43185 24.8516 5.55778 23.5994 3.97918 22.0208C2.40059 20.4422 1.14838 18.5682 0.294047 16.5056C-0.560282 14.4431 -1 12.2325 -1 10L7.5 10L9.38889 16.1389L14.5833 18.5L22.6111 16.1389L24.5 10H33Z" fill="white"/>
					<path d="M1 10H9C9 13 11.3693 17 16 17C20.6307 17 23 13 23 10H31C30 10 28 10.6 26 13C24 15.4 25.3333 18.6667 26.5 20C25.3333 19.3333 22.3 18.2 19.5 19C16.7 19.8 16.5 23.5 16 25C15.5 23.5 15.1 21.1 11.5 19.5C7.9 17.9 6 19.5 5 20.5C5.66667 19.1667 6.8 16 6 14C5.2 12 2.66667 10.5 1 10Z" fill="white"/>
					<rect width="37" height="1" transform="matrix(1 0 0 -1 -2 9)" fill="white"/>
				</g>
			</svg>
		</>
	);
}

export default Sunset;
import React from 'react';

function Text({ color = '#5C6684' }) {
	return (
		<svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M15 5.46875V14H13V5.46875H15ZM17 5V7H11V5H17Z" fill={color}/>
			<path d="M7 0V14H5V0H7ZM12 0V2H0V0H12Z" fill={color}/>
		</svg>
	);
}

export default Text;

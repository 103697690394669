import React from 'react';

function Publish({ stroke = '#5C6684' }) {
	return (
		<svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M15 1V20H1V5.41421L5.41421 1H15Z" fill="white" stroke={stroke} stroke-width="2"/>
			<rect x="7.5" y="14.5" width="5" height="1" rx="0.5" transform="rotate(-90 7.5 14.5)" stroke={stroke}/>
			<path d="M5 11L8 8L11 11" stroke={stroke} stroke-width="2" stroke-linecap="round"/>
		</svg>
	);
}

export default Publish;

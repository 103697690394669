import React from 'react';

function Img({ color1 = '#5C6684', color2 = '#fff' }) {
	return (
		<svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M18 23.5H3C2.72386 23.5 2.5 23.2761 2.5 23V1C2.5 0.723858 2.72386 0.5 3 0.5H13.9671C14.0944 0.5 14.217 0.548607 14.3097 0.6359L18.3427 4.43161C18.4431 4.5261 18.5 4.65784 18.5 4.79571V23C18.5 23.2761 18.2761 23.5 18 23.5Z" fill="white" stroke={color1}/>
			<rect y="10" width="21" height="8" rx="1" fill={color1}/>
			<path d="M5.10638 16.9198H4V11.0802H5.10638V16.9198Z" fill={color2}/>
			<path d="M7.58099 11.0802L8.96028 15.3155L10.3322 11.0802H11.7852V16.9198H10.6752V15.3235L10.7858 12.5682L9.33645 16.9198H8.57674L7.13106 12.5722L7.2417 15.3235V16.9198H6.13532V11.0802H7.58099Z" fill={color2}/>
			<path d="M17 16.1818C16.8009 16.4412 16.5193 16.643 16.1555 16.7874C15.7916 16.9291 15.3884 17 14.9458 17C14.4811 17 14.073 16.8904 13.7214 16.6711C13.3723 16.4492 13.1018 16.1283 12.9101 15.7086C12.7208 15.2888 12.6236 14.7955 12.6187 14.2286V13.8316C12.6187 13.2487 12.7085 12.7447 12.8879 12.3195C13.0699 11.8917 13.3305 11.5655 13.6698 11.3409C14.0115 11.1136 14.4111 11 14.8684 11C15.5052 11 16.003 11.1658 16.362 11.4973C16.7209 11.8262 16.9336 12.3061 17 12.9372H15.9231C15.8739 12.6029 15.7645 12.3583 15.5949 12.2032C15.4277 12.0481 15.1966 11.9706 14.9016 11.9706C14.5254 11.9706 14.239 12.1243 14.0423 12.4318C13.8456 12.7393 13.746 13.1965 13.7435 13.8035V14.1765C13.7435 14.7888 13.8505 15.2513 14.0644 15.5642C14.2783 15.877 14.5918 16.0334 15.0048 16.0334C15.4203 16.0334 15.7166 15.9372 15.8936 15.7447V14.738H14.8868V13.8516H17V16.1818Z" fill={color2}/>
		</svg>
	);
}

export default Img;

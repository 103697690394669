import React from 'react';
import { IS_SERVER } from 'src/constants';
import { useIconId } from 'src/front/hooks';

const ids = [];

function Send({ color1 = '#52B703', color2 = '#F6FCFF' }) {
	const id = useIconId(IS_SERVER ? 'Send' : ids);

	return (
		<>
			{IS_SERVER && id}
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clip-path={`url(#IconSend_${IS_SERVER ? '{print static_data.components.IconSend}' : id.current})`}>
					<circle cx="8" cy="8" r="8" fill={color1}/>
					<path d="M7 4L10 8L7 12" stroke={color2} stroke-width="1.5" stroke-linecap="round"/>
				</g>
				<defs>
					<clipPath id={`IconSend_${IS_SERVER ? '{print static_data.components.IconSend}' : id.current}`}>
						<rect width="16" height="16" fill="white"/>
					</clipPath>
				</defs>
			</svg>
		</>
	);
}

export default Send;
import React, { useRef, useEffect } from 'react';
import { IS_SERVER } from 'src/constants';

const ids = [];

const serverCalculations = () => `{exec}
	if (!('IconLike' in static_data.components)) static_data.components.IconLike = 0;
	else static_data.components.IconLike++;
{/exec}`;

function Like({ color = '#5C6684', fill = 'none' }) {
	const id = useRef(null);

	if (!IS_SERVER && id.current === null) {
		id.current = 0;
		while (ids.includes(id.current)) id.current++;
		ids.push(id.current);
	}

	useEffect(() => {
		return () => {
			const index = ids.indexOf(id.current);
			if (index >= 0) ids.splice(index, 1);
		};
	}, []);

	return (
		<>
			{IS_SERVER && serverCalculations()}
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clip-path={`url(#IconLike_${IS_SERVER ? '{print static_data.components.IconLike}' : id.current})`}>
					<path d="M9.53717 4.61713L8.96407 5.99998H10.461H14.3111C14.7232 5.99998 15 6.30174 15 6.64591L15 8.39334L12.6838 14.5518C12.6837 14.5522 12.6835 14.5526 12.6834 14.553C12.6336 14.6833 12.5453 14.796 12.4297 14.8759C12.3139 14.9559 12.1764 14.9992 12.0351 15H1.68889C1.30713 15 1 14.6897 1 14.3158V6.64591C1 6.33002 1.28535 5.99998 1.72717 5.99998H3.53828H3.96761L4.2633 5.68871L8.71731 1H10.0106C10.2318 1 10.4435 1.10857 10.5717 1.28578C10.6937 1.45446 10.7315 1.6741 10.6737 1.87475L9.53717 4.61713Z" stroke={color} fill={fill} stroke-width="2"/>
					<path d="M4.5 5V14" stroke={color}/>
				</g>
				<defs>
					<clipPath id={`IconLike_${IS_SERVER ? '{print static_data.components.IconLike}' : id.current}`}>
						<rect width="16" height="16" fill="white"/>
					</clipPath>
				</defs>
			</svg>
		</>
	);
}

export default Like;

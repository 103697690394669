import React from 'react';

function Star({ stroke = '#BFD0E0', fill = 'none' }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
			<path d="M17.988 6.97394L13.7838 6.36642C13.3957 6.29891 13.0723 6.02891 12.8783 5.6914L11.0025 1.70877C10.5498 0.763743 9.32086 0.763743 8.86809 1.70877L7.05704 5.6914C6.863 6.02891 6.5396 6.29891 6.15152 6.36642L2.01198 6.97394C1.04177 7.10894 0.653691 8.39148 1.36517 9.0665L4.40515 12.1716C4.66387 12.4416 4.79323 12.8466 4.72855 13.2516L4.01707 17.5718C3.82303 18.5843 4.85791 19.3268 5.69876 18.8543L9.45022 16.8292C9.77362 16.6267 10.2264 16.6267 10.5498 16.8292L14.3012 18.8543C15.1421 19.3268 16.177 18.5843 15.9829 17.5718L15.2714 13.2516C15.2068 12.8466 15.3361 12.4416 15.5949 12.1716L18.6348 9.0665C19.3463 8.39148 18.9582 7.10894 17.988 6.97394Z" fill={fill} stroke={stroke} stroke-width="1.8"/>
		</svg>
	);
}

export default Star;
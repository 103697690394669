import React from 'react';

function Message2({ color1 = '#ECEFF9', color2 = '#fff', ...rest }) {
	return (
		<svg {...rest} width="159" height="91" viewBox="0 0 159 91" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M26.5 70.5V67.5H23.5H5.60555L23.4769 40.693C25.4481 37.7362 26.5 34.262 26.5 30.7084V15C26.5 8.37258 31.8726 3 38.5 3H206.5C213.127 3 218.5 8.37258 218.5 15V126C218.5 132.627 213.127 138 206.5 138H38.5C31.8726 138 26.5 132.627 26.5 126V70.5Z" fill={color2} stroke={color1} stroke-width="6"/>
			<path d="M71.5 50C71.5 53.866 68.366 57 64.5 57C60.634 57 57.5 53.866 57.5 50C57.5 46.134 60.634 43 64.5 43C68.366 43 71.5 46.134 71.5 50Z" fill={color1}/>
			<path d="M106.5 50C106.5 53.866 103.366 57 99.5 57C95.634 57 92.5 53.866 92.5 50C92.5 46.134 95.634 43 99.5 43C103.366 43 106.5 46.134 106.5 50Z" fill={color1}/>
			<path d="M141.5 50C141.5 53.866 138.366 57 134.5 57C130.634 57 127.5 53.866 127.5 50C127.5 46.134 130.634 43 134.5 43C138.366 43 141.5 46.134 141.5 50Z" fill={color1}/>
		</svg>
	);
}

export default Message2;
import React from 'react';

function Share({ color = '#5C6684' }) {
	return (
		<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.40041 3.14869C2.73262 4.02859 -0.142624 8.59701 0.00756381 11C1.24555 8.52403 5.04376 7.48488 7.40136 7.14141C8.13214 7.03494 8.61142 7.77718 8.28115 8.4377C7.76359 9.47282 9.13391 10.4067 9.9081 9.54653L14 5.00001L9.53375 0.53378C8.78372 -0.216252 7.58556 0.736897 8.14773 1.63637C8.51874 2.23 8.08833 3.01902 7.40041 3.14869Z" fill={color}/>
		</svg>
	);
}

export default Share;

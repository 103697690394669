import React from 'react';

function Sun({ color = '#BFD0E0' }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
			<path d="M18.2111 11.9778L22 11L18.2111 10.0222C18.0889 8.67778 17.4778 7.45556 16.7444 6.47778L18.3333 3.66667L15.5222 5.25556C14.5444 4.52222 13.3222 3.91111 11.9778 3.78889L11 0L10.0222 3.78889C8.67778 3.91111 7.45556 4.4 6.47778 5.25556L3.66667 3.66667L5.25556 6.47778C4.52222 7.57778 3.91111 8.67778 3.78889 10.0222L0 11L3.78889 11.9778C3.91111 13.3222 4.52222 14.5444 5.25556 15.5222L3.66667 18.3333L6.47778 16.7444C7.45556 17.4778 8.67778 18.0889 10.0222 18.2111L11 22L11.9778 18.2111C13.3222 18.0889 14.5444 17.4778 15.5222 16.7444L18.3333 18.3333L16.7444 15.5222C17.4778 14.4222 18.0889 13.3222 18.2111 11.9778ZM11 15.8889C8.31111 15.8889 6.11111 13.6889 6.11111 11C6.11111 8.31111 8.31111 6.11111 11 6.11111C13.6889 6.11111 15.8889 8.31111 15.8889 11C15.8889 13.6889 13.6889 15.8889 11 15.8889Z" fill={color}/>
		</svg>
	)
}

export default Sun;
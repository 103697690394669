import React from 'react';

function Edit({ color, ...rest }) {
  return (
    <svg {...rest} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.83 1.35429L14.54 4.07429C15 4.53429 14.96 5.31429 14.57 5.71429L6.56 13.7343L1 14.8943L2.16 9.31429C2.16 9.31429 9.76 1.68429 10.15 1.28429C10.54 0.894291 11.37 0.894291 11.83 1.35429ZM9.1 4.14429L3.51 9.75429L4.62 10.8643L10.16 5.21429L9.1 4.14429ZM6.13 12.3743L11.71 6.77429L10.64 5.69429L5.05 11.2943L6.13 12.3743Z" fill={color}/>
    </svg>
  );
}

export default Edit;

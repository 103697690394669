import React from 'react';

function Save({ color = '#5C6684' }) {
	return (
		<svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M17 19H2C1.44772 19 1 18.5523 1 18V2C1 1.44772 1.44772 1 2 1H13.3088L18 5.67818V18C18 18.5523 17.5523 19 17 19Z" stroke={color} stroke-width="2"/>
			<rect x="4.5" y="0.5" width="6" height="4" rx="0.5" stroke={color}/>
			<rect x="4.5" y="11.5" width="10" height="8" rx="1.5" stroke={color}/>
		</svg>
	);
}

export default Save;
import React from 'react';
import { IS_SERVER } from 'src/constants';
import { useIconId } from 'src/front/hooks';

const ids = [];

function City({ color = '#fff' }) {
	const id = useIconId(IS_SERVER ? 'City' : ids);

	return (
		<>
			{IS_SERVER && id}
			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
				<g clip-path={`url(#IconCity_${IS_SERVER ? '{print static_data.components.IconCity}' : id.current})`}>
					<path d="M15 15V8H11V7H9V15H8V1H6V0H3V1H1V15H0V16H16V15H15ZM3 14H2V13H3V14ZM3 12H2V11H3V12ZM3 10H2V9H3V10ZM3 8H2V7H3V8ZM3 6H2V5H3V6ZM3 4H2V3H3V4ZM5 14H4V13H5V14ZM5 12H4V11H5V12ZM5 10H4V9H5V10ZM5 8H4V7H5V8ZM5 6H4V5H5V6ZM5 4H4V3H5V4ZM7 14H6V13H7V14ZM7 12H6V11H7V12ZM7 10H6V9H7V10ZM7 8H6V7H7V8ZM7 6H6V5H7V6ZM7 4H6V3H7V4ZM14 14H12V13H14V14ZM14 12H12V11H14V12ZM14 10H12V9H14V10Z" fill={color}/>
				</g>
				<defs>
					<clipPath id={`IconCity_${IS_SERVER ? '{print static_data.components.IconCity}' : id.current}`}>
						<rect width="16" height="16" fill={color}/>
					</clipPath>
				</defs>
			</svg>
		</>
	);
}

export default City;
import React, { useRef, useEffect } from 'react';
import { IS_SERVER } from 'src/constants';

const ids = [];

const serverCalculations = () => `{exec}
	if (!('IconCalendar' in static_data.components)) static_data.components.IconCalendar = 0;
	else static_data.components.IconCalendar++;
{/exec}`;

function Calendar({ color = '#5C6684' }) {
	const id = useRef(null);

	if (!IS_SERVER && id.current === null) {
		id.current = 0;
		while (ids.includes(id.current)) id.current++;
		ids.push(id.current);
	}

	useEffect(() => {
		return () => {
			const index = ids.indexOf(id.current);
			if (index >= 0) ids.splice(index, 1);
		};
	}, []);

	return (
		<>
			{IS_SERVER && serverCalculations()}
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clip-path={`url(#IconCalendar_${IS_SERVER ? '{print static_data.components.IconCalendar}' : id.current})`}>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M14 1H13C13 0.4 12.6 0 12 0C11.4 0 11 0.4 11 1H5C5 0.4 4.6 0 4 0C3.4 0 3 0.4 3 1H2C0.9 1 0 1.9 0 3V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V3C16 1.9 15.1 1 14 1ZM15 14C15 14.6 14.6 15 14 15H2C1.4 15 1 14.6 1 14V4C1 3.4 1.4 3 2 3C6.0273 3 10.1482 3 14 3C14.6 3 15 3.4 15 4V14Z" fill={color}/>
					<path d="M3 5H5V7H3V5Z" fill={color}/>
					<path d="M3 8H5V10H3V8Z" fill={color}/>
					<path d="M3 11H5V13H3V11Z" fill={color}/>
					<path d="M7 5H9V7H7V5Z" fill={color}/>
					<path d="M7 8H9V10H7V8Z" fill={color}/>
					<path d="M7 11H9V13H7V11Z" fill={color}/>
					<path d="M11 5H13V7H11V5Z" fill={color}/>
					<path d="M11 8H13V10H11V8Z" fill={color}/>
				</g>
				<defs>
					<clipPath id={`#IconCalendar_${IS_SERVER ? '{print static_data.components.IconCalendar}' : id.current}`}>
						<rect width="16" height="16" fill="white"/>
					</clipPath>
				</defs>
			</svg>
		</>
	);
}

export default Calendar;

import React from 'react';

function Bell({ color = '#fff' }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 14 17" fill="none">
			<path d="M13 12H12V7C12 4.6 10.3 2.6 8 2.1V2V1C8 0.4 7.6 0 7 0C6.4 0 6 0.4 6 1V2V2.1C3.7 2.6 2 4.6 2 7V12H1C0.4 12 0 12.4 0 13C0 13.6 0.4 14 1 14H2H4H10H13C13.6 14 14 13.6 14 13C14 12.4 13.6 12 13 12ZM10 12H4V7C4 5.3 5.3 4 7 4C8.7 4 10 5.3 10 7V12ZM5.1 15C5 15.2 5 15.3 5 15.5C5 16.3 5.7 17 6.5 17H7.5C8.3 17 9 16.3 9 15.5C9 15.3 9 15.2 8.9 15H5.1Z" fill={color}/>
		</svg>
	);
}

export default Bell;
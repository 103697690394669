import React from 'react';

export default ({ color, ...rest }) => {
  return (
    <svg {...rest} width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0.5C0 0.223858 0.223858 0 0.5 0H11.5C11.7761 0 12 0.223858 12 0.5C12 0.776142 11.7761 1 11.5 1H0.5C0.223858 1 0 0.776142 0 0.5Z" fill={color}/>
      <path d="M0 5.5C0 5.22386 0.223858 5 0.5 5H11.5C11.7761 5 12 5.22386 12 5.5C12 5.77614 11.7761 6 11.5 6H0.5C0.223858 6 0 5.77614 0 5.5Z" fill={color}/>
      <path d="M0 10.5C0 10.2239 0.223858 10 0.5 10H11.5C11.7761 10 12 10.2239 12 10.5C12 10.7761 11.7761 11 11.5 11H0.5C0.223858 11 0 10.7761 0 10.5Z" fill={color}/>
    </svg>
  );
};

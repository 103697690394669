import React from 'react';
import { IS_SERVER } from 'src/constants';
import { useIconId } from 'src/front/hooks';

const ids = [];

function Settings2({ color = '#5C6684', color2 = '#fff', ...rest }) {
	const id = useIconId(IS_SERVER ? 'Settings2' : ids);

	return (
		<>
			{IS_SERVER && id}
			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
				<g clip-path={`url(#IconSettings2_${IS_SERVER ? '{print static_data.components.IconSettings2}' : id.current})`}>
					<rect x="1" y="1" width="15" height="2" rx="1" fill={color}/>
					<rect y="7" width="16" height="2" rx="1" fill={color}/>
					<rect x="1" y="13" width="15" height="2" rx="1" fill={color}/>
					<circle cx="2" cy="2" r="1.5" fill="white" stroke={color}/>
					<circle cx="9" cy="8" r="1.5" fill="white" stroke={color}/>
					<circle cx="2" cy="14" r="1.5" fill="white" stroke={color}/>
				</g>
				<defs>
					<clipPath id={`IconSettings2_${IS_SERVER ? '{print static_data.components.IconSettings2}' : id.current}`}>
						<rect width="16" height="16" fill={color2}/>
					</clipPath>
				</defs>
			</svg>
		</>
	);
}

export default Settings2;
import React from 'react';

function Clock({ color = '#047FFD' }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
			<path opacity="0.2" d="M11 1V1C15.9706 1 20 5.02944 20 10V12H11V1Z" fill={color}/>
			<circle cx="11" cy="11" r="10" stroke={color} stroke-width="2"/>
			<rect x="10" y="5" width="2" height="7" rx="1" fill={color}/>
			<rect x="10" y="10" width="6" height="2" rx="1" fill={color}/>
		</svg>
	);
}

export default Clock;
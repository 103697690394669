import { useRef, useEffect } from 'react';
import { IS_SERVER } from 'src/constants';

const serverCalculations = (name) => `{exec}
	if (!('IconSend' in static_data.components)) static_data.components.Icon${name} = 0;
	else static_data.components.Icon${name}++;
{/exec}`;

export default (ids) => {
	const id = useRef(null);

	if (!IS_SERVER && id.current === null) {
		id.current = 0;
		while (ids.includes(id.current)) id.current++;
		ids.push(id.current);
	}

	useEffect(() => {
		return () => {
			const index = ids.indexOf(id.current);
			if (index >= 0) ids.splice(index, 1);
		};
	}, []);

	return IS_SERVER ? serverCalculations(ids) : id;
};
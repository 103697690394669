import React from 'react';

function FileAdd({ color = '#5C6684', ...rest }) {
	return (
		<svg {...rest} width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M15 1V20H1V5.41421L5.41421 1H15Z" fill="white" stroke={color} stroke-width="2"/>
			<rect x="4" y="10" width="8" height="2" rx="1" fill={color}/>
			<rect x="9" y="7" width="8" height="2" rx="1" transform="rotate(90 9 7)" fill={color}/>
		</svg>
	);
}

export default FileAdd;

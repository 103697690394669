import React from 'react';
import { IS_SERVER } from 'src/constants';
import { useIconId } from 'src/front/hooks';

const ids = [];

function Checkmark({ circleColor = '#52B703', markColor = '#fff' }) {
	const id = useIconId(IS_SERVER ? 'Checkmark' : ids);

	return (
		<>
			{IS_SERVER && id}
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clip-path={`url(#IconCheckmark_${IS_SERVER ? '{print static_data.components.IconCheckmark}' : id.current})`}>
					<circle cx="8" cy="8" r="8" fill={circleColor}/>
					<path d="M4.99988 8.41667L6.98738 10.5L10.9999 5.5" stroke={markColor} stroke-width="1.8" stroke-linecap="round"/>
				</g>
				<defs>
					<clipPath id={`IconCheckmark_${IS_SERVER ? '{print static_data.components.IconCheckmark}' : id.current}`}>
						<rect width="16" height="16" fill={markColor}/>
					</clipPath>
				</defs>
			</svg>
		</>
	);
}

export default Checkmark;

import React from 'react';

function MapToken({ color1 = '#BFD0E0', color2 = '#F6FCFF' }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
			<path d="M23 12.3043C23 17.7826 16 26 16 26C16 26 9 17.7826 9 12.3043C9 8.27027 12.134 5 16 5C19.866 5 23 8.27027 23 12.3043Z" fill={color1}/>
			<path d="M18 12C18 13.1046 17.1046 14 16 14C14.8954 14 14 13.1046 14 12C14 10.8954 14.8954 10 16 10C17.1046 10 18 10.8954 18 12Z" fill={color2}/>
		</svg>
	);
}

export default MapToken;
import React from 'react';

function Translate({ color }) {
  return (
    <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" fill="none" stroke={color}>
      <line x1="12.62" y1="24.31" x2="17.94" y2="11.42" stroke-linecap="round"/>
      <line x1="23.04" y1="24.35" x2="17.94" y2="11.42" stroke-linecap="round"/>
      <line x1="21.34" y1="20.02" x2="14.39" y2="20.02" stroke-linecap="round"/>
      <g>
        <line x1="32.63" y1="25.38" x2="39.35" y2="25.38" stroke-linecap="round"/>
        <path d="M35.68,25.38c0,4.34-5.29,11.51-10.59,12.61" stroke-linecap="round"/>
        <path d="M27.93,32.79c2.13,2.4,5.61,4.74,8.82,5.2" stroke-linecap="round"/>
      </g>
      <rect x="5.5" y="5.5" width="24.67" height="24.67" rx="3.64" ry="3.64" stroke-linecap="round"/>
      <path d="M17.83,30.17v8.69c0,2,1.64,3.64,3.64,3.64h17.38c2,0,3.64-1.64,3.64-3.64V21.47c0-2-1.64-3.64-3.64-3.64h-8.69" stroke-linecap="round"/>
    </svg>
  );
}

export default Translate;
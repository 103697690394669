import React from 'react';

function Device({ color = '#52B703' }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
			<rect x="0.5" y="0.5" width="9" height="9" rx="1.5" fill="white" stroke={color}/>
			<rect x="16.5" y="0.5" width="9" height="9" rx="1.5" fill="white" stroke={color}/>
			<rect x="0.5" y="16.5" width="9" height="9" rx="1.5" fill="white" stroke={color}/>
			<rect x="16.5" y="16.5" width="9" height="9" rx="1.5" fill="white" stroke={color}/>
			<rect x="10" y="3" width="6" height="1" fill={color}/>
			<rect x="10" y="19" width="6" height="1" fill={color}/>
			<rect x="10" y="6" width="6" height="1" fill={color}/>
			<rect x="10" y="22" width="6" height="1" fill={color}/>
			<rect x="3" y="10" width="1" height="6" fill={color}/>
			<rect x="6" y="10" width="1" height="6" fill={color}/>
			<rect x="19" y="10" width="1" height="6" fill={color}/>
			<rect x="22" y="10" width="1" height="6" fill={color}/>
		</svg>
	);
}

export default Device;
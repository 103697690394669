import React from 'react';
import { IS_SERVER } from 'src/constants';
import { useIconId } from 'src/front/hooks';

const ids = [];

function Sunrise() {
	const id = useIconId(IS_SERVER ? 'Sunrise' : ids);

	return (
		<>
			{IS_SERVER && id}
			<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
				<mask id={`IconSunrise_${IS_SERVER ? '{print static_data.components.IconSunrise}' : id.current}`} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
					<circle cx="16" cy="16" r="16" fill="#D9D9D9"/>
				</mask>
				<g mask={`url(#IconSunrise_${IS_SERVER ? '{print static_data.components.IconSunrise}' : id.current})`}>
					<circle cx="16" cy="16" r="16" fill="#FF8A00"/>
					<path opacity="0.2" d="M31 23C31 21.0302 30.612 19.0796 29.8582 17.2597C29.1044 15.4399 27.9995 13.7863 26.6066 12.3934C25.2137 11.0005 23.5601 9.89563 21.7403 9.14181C19.9204 8.38799 17.9698 8 16 8C14.0302 8 12.0796 8.38799 10.2597 9.14181C8.43986 9.89563 6.78628 11.0005 5.3934 12.3934C4.00052 13.7863 2.89563 15.4399 2.14181 17.2597C1.38799 19.0796 1 21.0302 1 23L6.5 23L10.2597 15H19L24 20L25 23H31Z" fill="white"/>
					<path opacity="0.2" d="M33 23C33 20.7675 32.5603 18.5569 31.706 16.4944C30.8516 14.4318 29.5994 12.5578 28.0208 10.9792C26.4422 9.40059 24.5682 8.14838 22.5056 7.29405C20.4431 6.43972 18.2325 6 16 6C13.7675 6 11.5569 6.43972 9.49438 7.29405C7.43185 8.14838 5.55778 9.40059 3.97918 10.9792C2.40059 12.5578 1.14838 14.4318 0.294047 16.4944C-0.560282 18.5569 -1 20.7675 -1 23L7.5 23L9.38889 16.8611L14.5833 14.5L22.6111 16.8611L24.5 23H33Z" fill="white"/>
					<path d="M1 23H9C9 20 11.3693 16 16 16C20.6307 16 23 20 23 23H31C30 23 28 22.4 26 20C24 17.6 25.3333 14.3333 26.5 13C25.3333 13.6667 22.3 14.8 19.5 14C16.7 13.2 16.5 9.5 16 8C15.5 9.5 15.1 11.9 11.5 13.5C7.9 15.1 6 13.5 5 12.5C5.66667 13.8333 6.8 17 6 19C5.2 21 2.66667 22.5 1 23Z" fill="white"/>
					<rect x="-2" y="24" width="37" height="1" fill="white"/>
				</g>
			</svg>
		</>
	);
}

export default Sunrise;
import React from 'react';


function Widget({ color = '#BFD0E0' }) {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1 2H12V10H1V2Z" stroke={color} stroke-width="2"/>
			<path d="M12.6842 5H15V13H4V10.7143" stroke={color} stroke-width="2"/>
		</svg>
	);
}

export default Widget;

import { useContext } from 'react';
import { IS_SERVER, IS_PRODUCTION } from 'src/constants';
import GlobalContext from 'src/context/GlobalContext';

export default (styles) => {
	const globalContext = useContext(GlobalContext);

	if (IS_SERVER && !IS_PRODUCTION) {
		const stylesString = styles.toString();
		if (!globalContext.staticContext.importedStyles.includes(stylesString)) globalContext.staticContext.importedStyles.push(stylesString);
	}
};
import React from 'react';

function Move({ color = '#BFD0E0' }) {
	return (
		<svg width="15" height="29" viewBox="0 0 15 29" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M6 0V29H4V6H0L6 0Z" fill={color}/>
			<path d="M9 29L9 -5.24537e-07L11 -3.49691e-07L11 23L15 23L9 29Z" fill={color}/>
		</svg>
	);
}

export default Move;

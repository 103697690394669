import React from 'react';

export default ({ fill, stroke, ...rest }) => {
  return (
    <svg width="30" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M9.48404 17.0452C8.15575 17.7409 7.45455 18.5685 7.28882 19.3504L15.5 25.0852L23.7112 19.3504C23.5455 18.5685 22.8442 17.7409 21.516 17.0452C20.0148 16.2589 17.8891 15.75 15.5 15.75C13.1109 15.75 10.9852 16.2589 9.48404 17.0452Z" fill={fill} stroke={stroke} stroke-width="1.5"/>
      <path d="M18.5 16C18.5 16.7956 18.1839 17.5587 17.6213 18.1213C17.0587 18.6839 16.2956 19 15.5 19C14.7044 19 13.9413 18.6839 13.3787 18.1213C12.8161 17.5587 12.5 16.7956 12.5 16L15.5 16H18.5Z" fill={fill}/>
      <path d="M19.75 7.99992C19.75 10.3471 17.8472 12.2499 15.5 12.2499C13.1528 12.2499 11.25 10.3471 11.25 7.99992C11.25 5.65271 13.1528 3.74992 15.5 3.74992C17.8472 3.74992 19.75 5.65271 19.75 7.99992Z" fill={fill} stroke={stroke} stroke-width="1.5"/>
    </svg>
  );
};

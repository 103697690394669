import React from 'react';

export default ({ width = 16, height = 16, ...props }) => (
  <svg {...props} width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1" fill="white">
    <rect y="2" width="16" height="12" rx="1"/>
    </mask>
    <rect y="2" width="16" height="12" rx="1" stroke="#5B656F" strokeWidth="3" mask="url(#path-1-inside-1)"/>
    <path d="M1 3L8 8L15 3" stroke="#5B656F"/>
    <path d="M15 13L10 7" stroke="#5B656F"/>
    <path d="M1 13L6 7" stroke="#5B656F"/>
  </svg>
);
import React from 'react';

function Desktop({ color1 = '#fff', color2 = 'none' }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="26" height="20" viewBox="0 0 26 20" fill="none">
			<rect x="1" y="15" width="14" height="24" rx="1" transform="rotate(-90 1 15)" stroke={color1} fill={color2} stroke-width="2"/>
			<rect x="6" y="19" width="14" height="1" fill={color1}/>
			<circle cx="13" cy="16" r="1" fill={color1}/>
		</svg>
	);
}

export default Desktop;
import React from 'react';

function Exit({ color = '#F65E5D' }) {
	return (
		<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M10 8H5C4.44772 8 4 7.55228 4 7C4 6.44772 4.44772 6 5 6H10V4L14 7L10 10V8ZM9 10V13C9 13.5523 8.55229 14 8 14H1C0.447715 14 0 13.5523 0 13V1C0 0.447716 0.447715 0 1 0H8C8.55229 0 9 0.447715 9 1V4H8V1.5C8 1.22386 7.77614 1 7.5 1H3V13H7.5C7.77614 13 8 12.7761 8 12.5V10H9Z" fill={color}/>
		</svg>
	);
}

export default Exit;

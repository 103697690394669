import React from 'react';

function Warning({ color = '#BFD0E0', ...rest }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="34" height="30" viewBox="0 0 34 30" fill="none" {...rest}>
			<path d="M2.20067 26.0109L15.2617 3.05524C16.0286 1.70731 17.9714 1.70731 18.7383 3.05525L31.7993 26.011C32.5579 27.3442 31.595 29 30.061 29H3.93899C2.405 29 1.44207 27.3442 2.20067 26.0109Z" stroke={color} stroke-width="2"/>
			<path d="M17.7564 20.9715H16.2436L15.5 8.35608C15.5 8.11869 15.6453 8 15.9359 8H18.0385C18.3462 8 18.5 8.12717 18.5 8.38152L17.7564 20.9715Z" fill={color}/>
			<path d="M16.2094 22.921C15.9929 23.154 15.8847 23.4423 15.8847 23.7857C15.8847 24.1169 15.9929 24.4051 16.2094 24.6504C16.4374 24.8835 16.7052 25 17.0129 25C17.3206 25 17.5827 24.8835 17.7992 24.6504C18.0271 24.4051 18.1411 24.1169 18.1411 23.7857C18.1411 23.4545 18.0271 23.1724 17.7992 22.9394C17.5827 22.6941 17.3206 22.5714 17.0129 22.5714C16.6938 22.5714 16.426 22.688 16.2094 22.921Z" fill={color}/>
		</svg>
	);
};

export default Warning;
import React from 'react';

function Mobile({ color1 = '#fff', color2 = 'none' }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="26" viewBox="0 0 16 26" fill="none">
			<rect x="1" y="1" width="14" height="24" rx="1" stroke={color1} fill={color2} stroke-width="2"/>
			<rect x="6" y="2" width="4" height="1" fill={color1}/>
			<circle cx="8" cy="21" r="1" fill={color1}/>
		</svg>
	);
}

export default Mobile;
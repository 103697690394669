import React from 'react';

function Message({ color1 = '#F6FCFF', color2 = '#5C6684', color3 = '#5C6684' }) {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1 3C1 2.44772 1.44772 2 2 2H14C14.5523 2 15 2.44772 15 3V11C15 11.5523 14.5523 12 14 12H2C1.44772 12 1 11.5523 1 11V3Z" fill={color1} stroke={color2} stroke-width="2"/>
			<path d="M12.5 12.5V14.7929L10.2071 12.5H12.5Z" fill={color2} stroke={color2}/>
			<path d="M6 7C6 7.55228 5.55228 8 5 8C4.44772 8 4 7.55228 4 7C4 6.44772 4.44772 6 5 6C5.55228 6 6 6.44772 6 7Z" fill={color3}/>
			<path d="M9 7C9 7.55228 8.55228 8 8 8C7.44772 8 7 7.55228 7 7C7 6.44772 7.44772 6 8 6C8.55228 6 9 6.44772 9 7Z" fill={color3}/>
			<path d="M12 7C12 7.55228 11.5523 8 11 8C10.4477 8 10 7.55228 10 7C10 6.44772 10.4477 6 11 6C11.5523 6 12 6.44772 12 7Z" fill={color3}/>
		</svg>
	);
}

export default Message;
import React from 'react';

function File({ color = '#566282' }) {
	return (
		<svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M11.5 0.5V15.5H0.5V4.20711L4.20711 0.5H11.5Z" stroke={color}/>
			<rect x="5" y="5" width="4" height="1" fill={color}/>
			<rect x="3" y="8" width="6" height="1" fill={color}/>
			<rect x="3" y="11" width="6" height="1" fill={color}/>
		</svg>
	);
}

export default File;

import React from 'react';

function Moon({ color = '#BFD0E0' }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
			<path d="M15.1887 13.9557C13.6096 15.8176 11.2478 17 8.6087 17C3.85424 17 0 13.1624 0 8.42857C0 4.30846 2.91961 0.867304 6.81126 0.0442567C5.54555 1.53654 4.78261 3.46527 4.78261 5.57143C4.78261 10.3053 8.63685 14.1429 13.3913 14.1429C14.0077 14.1429 14.609 14.0783 15.1887 13.9557Z" fill={color}/>
		</svg>
	);
}

export default Moon;
import React, { useEffect, useRef } from 'react';

const styles = {
  sensor: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    visibility: 'hidden',
    overflow: 'hidden',
    direction: 'ltr',
  },
};

export default React.forwardRef(({ children, className, style = {}, callback, visibilityCheck = 'windowResize', instant = false, ...rest }, ref) => {
  const expandRef = useRef(null);
  const shrinkRef = useRef(null);
  const timer = useRef(null);
  const callbackRef = useRef(null);
  const prevSize = useRef(null);

  callbackRef.current = callback;

  useEffect(() => {
    let scrollCheckRunned = false;
    let checkTimer = null;
    const scroll = function (runCallback = true) {
      if (expandRef.current && expandRef.current.offsetWidth !== 0) {
        const width = expandRef.current.offsetWidth;
        const height = expandRef.current.offsetHeight;
        const expandChild = expandRef.current.getElementsByTagName('div')[0];
        const sizeChanged = !prevSize.current || prevSize.current.width !== width || prevSize.current.height !== height;

        if (sizeChanged) prevSize.current = { width, height };
  
        expandChild.style.width = `${width + 10}px`;
        expandChild.style.height = `${height + 10}px`;

        expandRef.current.scrollLeft = 10;
        expandRef.current.scrollTop = 10;
  
        shrinkRef.current.scrollLeft = width;
        shrinkRef.current.scrollTop = height;
  
        if (runCallback && timer.current === null) {
          if (instant) {
            if (callbackRef.current && sizeChanged) callbackRef.current();
          } else {
            timer.current = setTimeout(() => {
              if (callbackRef.current && sizeChanged) callbackRef.current();
              timer.current = null;
            }, 20);
          }
        }
        if (checkTimer) {
          clearTimeout(checkTimer);
          checkTimer = null;
        }
        if (runCallback) checkTimer = setTimeout(() => (scroll(false), checkTimer = null), 500);
      } else {
        scrollCheckRunned = false;
        check();
      }
    };

    function check() {
      if (!expandRef.current || expandRef.current.offsetWidth === 0) {
        switch (visibilityCheck) {
          case 'windowResize': {
            const resize = () => {
              if (expandRef.current && expandRef.current.offsetWidth !== 0) {
                scroll();
                window.removeEventListener('resize', resize);
              }
            };
            window.addEventListener('resize', resize);
            break;
          }

          case 'timer': {
            setTimeout(check, 1000);
            break;
          }

          default: {
            if (visibilityCheck.constructor === Function) visibilityCheck(check);
          }
        }
      } else if (!scrollCheckRunned) {
        scrollCheckRunned = true;
        scroll();
      }
    };

    expandRef.current.addEventListener('scroll', scroll);
    shrinkRef.current.addEventListener('scroll', scroll);

    check();

    return () => callbackRef.current = null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const props = {
    style: { position: 'relative', ...style },
    className: `resize-sensible${className ? ' ' + className : ''}`,
    ...rest
  };

  if (ref) props.ref = ref;

  return (
    <div {...props}>
      {children}
      <resize-sensible-sensor ref={expandRef} style={styles.sensor}>
        <div/>
      </resize-sensible-sensor>
      <resize-sensible-sensor ref={shrinkRef} style={styles.sensor}>
        <div style={{ width: '200%', height: '200%' }}/>
      </resize-sensible-sensor>
    </div>
  )
});